<template>
  <div class="bodyPropostaEnviada">
    <div class="alignMobile">
      <img
        style="margin: 15px auto 25px auto; display: block"
        src="/img/logo_pagseguro.png"
        width="250px"
        alt="PagSeguro Logo"
      />
      <p style="font-weight: bold">Contrato já negociado</p>
      <p>Aguarde nosso contato</p>
      <p class="mb-8">
        ou ligue no <a href="tel:08005252030"> 0800 525 2030</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropostaEnviada",
  mounted() {
    localStorage.clear();
  },
};
</script>

<style scoped>
.bodyPropostaEnviada {
  font-family: "Nunito", sans-serif;
  background-color: white;
  width: 600px;
  border-radius: 8px;
  padding-bottom: 25px;
  padding-top: 5px;
  margin-top: 30px;
}

.alignMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  margin: 5px;
  text-align: center;
  font-size: 22px;
}

@media (max-width: 600px) {
  .bodyPropostaEnviada {
    width: 95vw;
    margin-top: 30px;
  }
}
</style>